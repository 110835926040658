.deletedUser {
  display: flex;

  &__label {
    font-family: 'Jost';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    color: #43434399;
  }

  &__value {
    font-family: 'Jost';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    margin-left: 16px;
    color: #43434399;
  }
}
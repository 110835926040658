@import "../../../../styles/constants";

.login {
  background: $full-color-background;
  width: 100vw;
  height: 100vh;

  .main-card-container {
    margin-top: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding: 30px;
    border: 3px solid #C00000;
    box-shadow: 0 8px 30px 0 rgba(177, 177, 177, 0.5);
  }

  .header-text {
    font-weight: bold;
    font-size: 50px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
  }

  .welcome-text {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .main-action {
    display: inline-block;
    font-weight: bold;
  }

  .sso-login {
    margin-top: 20px;
    display: inline-block;
    font-weight: bold;
    color: black;
    background-color: white;
    border: 1px solid black;
  }

  .login-spinner {
    display: inline-block;
    margin-bottom: 26px !important;
    margin-left: 50px !important;
  }

  .recover-password-text {
    color: #0D326E;
    font-size: 15px;

    a {
      margin-left: 10px;
    }
  }
  .register-text {
    color: #0D326E;
    font-size: 15px;
    margin-top: 15px;

    a {
      margin-left: 10px;
    }
  }
  
  .footer {
    margin: auto;
    padding-bottom: 20px;
  }
  
  .footer-text {    
    color: $white;
  }
  .loginError {
    color: #C00000;
  }
}
